import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import Uppy from '@uppy/core'
import GoldenRetriever from '@uppy/golden-retriever'
import GDriveResumableUpload from './plugins/GDriveResumableUpload'
import de_DE from '@uppy/locales/lib/de_DE'
import { Dashboard } from '@uppy/react'
import logo from './assets/logo.svg';
import { Mail, Info, AlertTriangle } from 'react-feather';

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const KB = 1024; // 1024 Byte
const MB = 1024 * KB; // 1024 KB
const GB = 1024 * MB; // 1024 MB

const App = () => {
  const { id } = useParams();
  const [project, setProject] = useState('loading');
  const [uppy, setUppy] = useState(null);

  // get project info
  useEffect(() => {
    if (id) {
      const url = process.env.REACT_APP_API_BASE_URL + id;
      fetch(url)
      .then(res => res.json())
      .then(res => {
        setProject(res);

        // set the title
        document.title = `[${res.code}] - VISENDA Drop`;

        // TODO: useUppy needed here?
        const uppy = new Uppy({
          logger: Uppy.debugLogger,
          locale: de_DE,
          restrictions: {
            maxNumberOfFiles: 1000,
            maxFileSize: 250 * GB
          }
        }).use(GDriveResumableUpload, {
          queryUrl: process.env.REACT_APP_API_BASE_URL + 'upload/' + id,
          endpointPrefix: process.env.REACT_APP_QUERY_URL_PREFIX
        }).use(GoldenRetriever, {
          serviceWorker: true
        });

        setUppy(uppy);
      })
      .catch((err) => {
        setProject('not-found');
        throw err;
      })
    }
  }, [id]);


  return (
    <>
      {
        (uppy && project?.code && project?.title) ?
          <>
            <nav className="navbar navbar-light bg-dark justify-content-between justify-content-md-around align-items-center py-3 px-4">
              <div className="row g-0 w-100">
                <div className="col-4 col-md-4 d-flex align-items-center justify-content-start justify-content-md-center pe-3">
                  <img src={logo}
                       className="logo"
                       alt="VISENDA Logo"/>
                </div>

                <div className="col-8 col-md-4 d-flex align-items-center">
                  <div className="text-right text-md-center w-100">
                    <span className="d-block text-truncate overflow-hidden">
                      {`[${project.code}] ${project.title}`}
                    </span>
                    <a className="d-flex align-items-center d-md-none small text-secondary text-decoration-none"
                       href={`mailto:${project.responsible?.email}`}
                    ><Mail className="me-1" size={15}/> <span className="me-4">{project.responsible?.name}</span></a>
                  </div>
                </div>

                <div className="d-none col-md-4 d-md-flex align-items-center justify-content-center">
                  <div className="d-block">
                    <span className="d-block small text-secondary">Ihr Ansprechpartner: </span>
                    <a className="d-inline-flex align-items-center text-decoration-none"
                       href={`mailto:${project.responsible?.email}`}
                    >
                      <Mail className="me-1" size={20} /> <span>{project.responsible?.name}</span>
                    </a>
                  </div>
                </div>
              </div>
            </nav>

            <div className="container py-4 px-4 px-md-0">
              <div className="row g-0">
                <div className="alert alert-danger w-100 d-flex align-items-center" role="alert">
                  <span className="me-2"><Info size={22}/></span>
                  <span className="ps-2">Bitte vermeiden Sie das Erstellen von ZIP oder RAR Archiven für den Upload. Sie können bis zu {uppy.opts.restrictions.maxNumberOfFiles} einzelne Dateien pro Uploadvorgang auswählen.</span>
                </div>
              </div>
              <div className="uppy-container row g-0 d-flex justify-content-center mt-2">
                <Dashboard
                  uppy={uppy}
                  inline={true}
                  width={null}
                  height={null}
                  showProgressDetails={true}
                  proudlyDisplayPoweredByUppy={false}
                  metaFields={[
                    { id: 'name', name: 'Dateiname', placeholder: 'Dateiname' },
                  ]}
                  theme={'dark'}
                  /*note={`[${project.code}] ${project.title}`}*/
                />
              </div>
              <span className="d-block small text-center mt-3 w-75 mx-auto fw-light">Die maximale Dateigröße beträgt {uppy.opts.restrictions.maxFileSize / GB} GB. Sie können den Upload von großen Datenmengen innerhalb von 7 Tagen jederzeit fortsetzen.</span>
            </div>
          </>
          :
          <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
            {
              (id && project === 'loading') ?
                <div className="text-center">
                  <div className="d-block w-100">
                    <div className="spinner-border" role="status"/>
                  </div>
                  <span className="d-block w-100 mt-1">Lade Projekt</span>
                </div>
                :
                <div className="text-center">
                  <div className="d-block w-100">
                    <AlertTriangle size={40}/>
                  </div>
                  <span className="d-block w-100 mt-2 fw-bold">Projekt konnte nicht gefunden werden</span>
                  <span className="d-block w-100 small">Bitte überprüfen Sie den Link noch einmal.</span>
                </div>
            }
          </div>
      }
    </>
  );
};

export default App;

import BasePlugin from '@uppy/core/lib/BasePlugin.js'
import Translator from '@uppy/utils/lib/Translator';
import ProgressTimeout from '@uppy/utils/lib/ProgressTimeout';

class GDriveResumableUpload extends BasePlugin {
  constructor(uppy, opts) {
    const defaultOptions = {
      queryUrl: '',
      endpointPrefix: ''
    };

    super(uppy, { ...defaultOptions, ...opts });
    this.id = this.opts.id || 'GDriveResumableUpload';
    this.type = 'uploader';

    this.defaultLocale = {
      strings: {
        preparingUploadLocations: 'Preparing...',
        uploadFailedRestart: 'Upload failed. Please restart!',
        uploadExpiredRestart: 'Upload expired. Please restart!',
        uploadLocationMissing: 'Error: Upload Location is missing!',
        retryUpload: 'Uploading failed. Retrying...'
      }
    }

    // should not be overridden as we use them internally
    delete this.opts.success;
    delete this.opts.error;

    this.i18nInit(); // enable locales
    this.prepareUpload = this.prepareUpload.bind(this);
    this.getUploadLocations = this.getUploadLocations.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  // set-up a logger
  log(message, type) {
    return this.uppy.log(`[${this.id}] ${message}`, type)
  }

  setOptions(newOpts) {
    super.setOptions(newOpts)
    this.i18nInit()
  }

  i18nInit() {
    this.translator = new Translator([this.defaultLocale, this.uppy.locale, this.opts.locale])
    this.i18n = this.translator.translate.bind(this.translator)
    this.setPluginState(); // trigger UI re-render to see updated locale
  }

  /**
   * Fetches upload info (location etc.) from the PPS
   * Will only return upload locations for files that lack of none
   *
   * @param files The files
   * @return {Promise<{id: string, location: string}[]>} The upload locations
   */
  getUploadLocations(files) {
    // prepare payload
    const destFiles = files
    .filter((file) => !file.gDriveResumableUpload?.uploadLocation) // filter if uploadLocation already exists
    .map(file => ({
        id: file.id,
        name: file.meta.name,
        size: file.size,
        mimeType: file.meta.type
      })
    );

    if (destFiles.length > 0) {
      // prepare request (for the PPS, not for GDrive!)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(destFiles)
      };

      return fetch(this.opts.queryUrl, requestOptions)
      .then(res => res.json());
    } else {
      return Promise.resolve([]);
    }
  }

  /**
   * Sets the upload endpoint for each file.
   *
   * @return {Promise<void>}
   */
  prepareUpload() {
    /*this.log(`Started preparing files for upload...`);*/
    const files = this.uppy.getFiles();

    // announce preprocess for all files except restored and paused ones. They have been already prepared.
    files
    .filter((file) => !(file.isRestored === true || file.isPaused === true || file.progress.uploadComplete === true))
    .forEach((file) => {
      this.uppy.emit('preprocess-progress', file, {
        mode: 'indeterminate',
        message: this.i18n('preparingUploadLocations')
      })
    });

    // will only return uploadLocations for files that lack of none
    return this.getUploadLocations(files)
    .then((uploadLocations) => uploadLocations.forEach((uploadLocation) => {
      // set the upload location for each file individually
      this.uppy.setFileState(uploadLocation.id, {
        gDriveResumableUpload: {
          endpointPrefix: this.opts.endpointPrefix, // used for the Proxy (e.g. CF Worker)
          uploadLocation: uploadLocation.location, // used for the GDrive upload URL
          totalBytesUploaded: 0 // used to store the last GDrive confirmed value (e.g. for retry)
        }
      });

      // announce preprocess complete
      this.uppy.emit('preprocess-complete', this.uppy.getFile(uploadLocation.id));
      this.log(`Prepared file for upload (id=${uploadLocation.id})`);
    }));
  }

  /**
   * Triggers the upload process for all files
   *
   * @param fileIds The File IDs
   * @return {Promise<void>}
   */
  handleUpload(fileIds) {
    this.log(`Starting upload process...`);
    const uploads = fileIds.map((fileId) => this.uploadFile(fileId));

    return Promise
    .all(uploads)
    .then(() => {
      this.log(`Finished upload process`);
      return Promise.resolve(); // Promise<void> is expected
    });
  }

  /**
   * Returns the Chunk size for a file, depending on its size.
   * The chunk sizes will be chosen to be equally distributed.
   *
   * @param file The File
   * @return {number|*} The Chunk size in Byte
   */
  getChunkSize(file) {
    const totalBytesUploaded = file.gDriveResumableUpload.totalBytesUploaded;
    const quarterMb = 256 * 1024;
    const mb = 4 * quarterMb; // 1 MB

    // max. 100 MB, Cloudflare limit
    // https://developers.cloudflare.com/workers/platform/limits#request-limits
    const maxChunkSizeInByte = 99 * mb; // use 99 MB instead of 100 MB for possible request overhead
    const minChunkSizeInByte = 5 * mb; // 5 MB, we define it so

    // upload files smaller than 2 * minChunkSizeInByte (=10MB) in a single chunk.
    // This function will not be called again then.
    if (file.size <= 2 * minChunkSizeInByte) {
      return file.size;
    } else {
      // we must send chunks that are multiples of quarterMb (except for the last chunk)
      // https://developers.google.com/drive/api/v3/manage-uploads#http---multiple-requests
      const numberOfChunks = 1 + (1 / 120) * (file.size / mb) + 0.917; // linear function 10 to 1000
      const quarterMbFactor = Math.ceil((file.size / numberOfChunks) / quarterMb);
      const computedChunkSizeInByte = Math.min(quarterMbFactor * quarterMb, maxChunkSizeInByte);
      this.log(`This is Chunk ${Math.ceil(totalBytesUploaded / computedChunkSizeInByte) + 1} of ${Math.ceil(
        numberOfChunks)} (id=${file.id})`);
      this.log(`Computed Chunk size: ${computedChunkSizeInByte} (${this.inMB(computedChunkSizeInByte)} MB) (id=${file.id})`);

      // if last chunk, send the rest as one chunk
      let realChunkSizeInByte = Math.min(file.size - totalBytesUploaded,
        computedChunkSizeInByte);

      // if the next (last) chunk is smaller than 1MB, add it to save another request
      const restChunkSizeInByte = file.size - (totalBytesUploaded + realChunkSizeInByte);
      if (restChunkSizeInByte > 0 && restChunkSizeInByte <= 1 * mb) {
        this.log(`Adding last Chunk to this request: ${restChunkSizeInByte} (${this.inMB(
          restChunkSizeInByte)} MB) (id=${file.id})`);
        realChunkSizeInByte += restChunkSizeInByte;
      }
      this.log(`Real Chunk size: ${realChunkSizeInByte} (${this.inMB(realChunkSizeInByte)} MB) (id=${file.id})`);

      return realChunkSizeInByte;
    }
  }

  /**
   * Returns the upload status
   *
   * @param status The status code
   * @return {string} The current upload status
   */
  getUploadStatus(status) {
    if (status >= 200 && status < 300) return "finished";
    if (status === 308) return "resume";
    if (status === 400) return "failed";
    if (status === 404) return "expired";
    if (status >= 500 && status < 600) return "retry";

    return "unknown";
  }

  /**
   * Uploads a single file in multiple chunks.
   *
   * @param fileId The file ID
   * @return {Promise<void>}
   */
  async uploadFile(fileId) {
    let file = this.uppy.getFile(fileId);

    // announce upload started
    this.uppy.emit('upload-started', file);
    this.log(`Starting file upload. Total size: ${this.inMB(file.size)} MB (id=${file.id})`);
    let isUploadInProgress = true;

    // upload all chunks sequentially
    while (isUploadInProgress) {
      const chunkSize = this.getChunkSize(file);
      const currentChunk = await this.uploadChunk(file, chunkSize);
      const uploadStatus = this.getUploadStatus(currentChunk?.status);

      switch (uploadStatus) {
        case "finished":
          this.log(`File upload finished (id=${fileId})`);
          isUploadInProgress = false;
          this.uppy.emit('upload-success', file, {
            status: currentChunk.status, // pass the GDrive response, 200 is ok
          });
          break;
        case "retry":
          this.log(`Retrying Chunk upload (id=${fileId})`);
          this.uppy.emit('upload-retry', file, this.i18n('retryUpload'));

          // set bytesUploaded to last confirmed value and retry
          this.uppy.emit('upload-progress', file, {
            uploader: this,
            bytesUploaded: file.gDriveResumableUpload.totalBytesUploaded,
            bytesTotal: file.size,
          });
          break;
        case "resume":
          const totalUploadedPercent = this.round2(currentChunk.totalBytesUploaded / (file.progress.bytesTotal / 100));
          this.log(`Resume uploading next Chunk (${totalUploadedPercent}% done) (id=${fileId})`);

          // set the totalBytesUploaded received from GDrive
          // "Do not assume that the server received all bytes sent in the previous request."
          // see https://developers.google.com/drive/api/v3/manage-uploads#http---multiple-requests
          this.uppy.emit('upload-progress', file, {
            uploader: this,
            bytesUploaded: currentChunk.totalBytesUploaded,
            bytesTotal: file.size,
          });

          // store for recovery (e.g. for retry)
          this.uppy.setFileState(file.id, {
            gDriveResumableUpload: {
              ...file.gDriveResumableUpload,
              totalBytesUploaded: currentChunk.totalBytesUploaded
            }
          });
          break;
        case "expired":
          this.log(`File upload expired (id=${fileId})`);
          this.uppy.emit('upload-error', file, this.i18n('uploadExpiredRestart'));
          isUploadInProgress = false;
          break;
        default:
          // need to start over completely
          this.log(`File upload failed (id=${fileId})`);
          this.uppy.emit('upload-error', file, this.i18n('uploadFailedRestart'));
          isUploadInProgress = false;
          break;
      }

      file = this.uppy.getFile(fileId); // file object update
    }
  }

  /**
   * Uploads a file chunk.
   *
   * @param file The file
   * @param startByte The start byte
   * @param chunkSize The chunk size
   * @return {Promise<unknown>}
   */
  uploadChunk(file, chunkSize) {
    return new Promise((resolve, reject) => {
      const { endpointPrefix, uploadLocation, totalBytesUploaded } = file?.gDriveResumableUpload;

      // cannot proceed if uploadLocation is missing
      if (!uploadLocation) {
        const error = new Error(this.i18n('uploadLocationMissing'));
        this.uppy.emit('upload-error', file, error);
        reject(error);
      }

      const uploadUrl = (endpointPrefix ? endpointPrefix : '') + uploadLocation;
      const xhr = new XMLHttpRequest();

      // setup timer for upload timeout
      const uploadTimeout = 30 * 60 * 1000; // set timeout to 30 minutes (for whole chunk)
      const timer = new ProgressTimeout(uploadTimeout, () => {
        xhr.abort();
        const error = new Error(this.i18n('timedOut',
          { seconds: Math.ceil(uploadTimeout / 1000) }));
        this.uppy.emit('upload-error', file, error);
        reject(error);
      });

      // define chunk upload start event
      xhr.upload.addEventListener('loadstart', (ev) => {
        this.log(`Chunk upload started (id=${file.id})`);
      });

      // define chunk upload progress event
      xhr.upload.addEventListener('progress', (ev) => {
        // ev.loaded is currently uploaded bytes
        this.log(`Chunk upload progress: ${this.round2(ev.loaded / (chunkSize / 100))}% (id=${file.id})`);

        // Begin checking for timeouts when progress starts
        timer.progress();

        if (ev.lengthComputable) {
          this.uppy.emit('upload-progress', file, {
            uploader: this,
            bytesUploaded: totalBytesUploaded + ev.loaded,
            bytesTotal: file.size,
          });
        } else {
          this.log(`Length is not computable. Server did not send a Content-Length (id=${file.id})`,
            'error');
        }
      });

      // define chunk upload done event. Also triggered on error/cancel.
      xhr.addEventListener('load', (ev) => {
        timer.done();
        this.log(`Chunk upload done (id=${file.id})`);

        return resolve({
          status: ev.target.status,
          totalBytesUploaded: this.parseBytesUploaded(ev, xhr, file),
          file: file
        });
      });

      // define chunk upload failed event
      xhr.addEventListener('error', () => {
        this.log(`Chunk upload failed (id=${file.id})`, 'error');
        timer.done();
        xhr.abort();

        return resolve({
          status: 500,
          error: xhr.responseText,
        });
      })

      // define pause event
      this.uppy.on('pause-all', () => {
        return xhr.abort();
      });

      // define cancel event
      this.uppy.on('cancel-all', () => {
        return xhr.abort();
      })

      // trigger chunk upload
      const startByte = file.gDriveResumableUpload.totalBytesUploaded;
      const data = file.data.slice(startByte, startByte + chunkSize);
      xhr.open("PUT", uploadUrl, true);
      xhr.setRequestHeader("Content-Range",
        `bytes ${startByte}-${startByte + chunkSize - 1}/${file.size}`);
      xhr.send(data);
    })
  }

  /**
   * Parses the bytes uploaded from response
   *
   * @param ev The XHR Event
   * @param xhr The XHR Object
   * @param file The File
   * @return {number} The Chunk size in Byte
   */
  parseBytesUploaded(ev, xhr, file) {
    switch (this.getUploadStatus(ev.target.status)) {
      case 'resume':
        const rangeHeader = xhr.getResponseHeader("Range");
        return parseInt(rangeHeader.split("-")[1]);
      case 'finished':
        // return file.size if status is finished, because there is no Range Header
        return file.size;
      default:
        // return the last confirmed totalBytesUploaded otherwise
        return file.gDriveResumableUpload.totalBytesUploaded;
    }
  }

  /**
   * Rounds a number to 2 decimals
   *
   * @param x The Number
   * @return {number} The Result
   */
  round2(x) {
    return Math.round(x * 100) / 100;
  }

  /**
   * Converts Bytes to MB, rounded to 2 decimals
   *
   * @param bytes The Bytes
   * @return {number} The MB
   */
  inMB(bytes) {
    return this.round2(bytes / 1024 / 1024);
  }

  install() {
    const { capabilities } = this.uppy.getState();
    this.uppy.setState({
      capabilities: {
        ...capabilities,
        resumableUploads: true,
      },
    });
    this.uppy.addPreProcessor(this.prepareUpload);
    this.uppy.addUploader(this.handleUpload);

    // define resume-all event
    this.uppy.on('resume-all', () => {
      this.log(`Preparing all files for resuming upload...`);
      const files = this.uppy.getFiles();

      // set the current bytesUploaded state
      /*files.forEach((file) => {
        const bytesUploaded = file?.gDriveResumableUpload?.totalBytesUploaded;
        this.uppy.emit('upload-progress', file, {
          uploader: this,
          bytesUploaded: bytesUploaded ? bytesUploaded : 0, // reset completely else
          bytesTotal: file.size,
        })
      });*/

      // we need to trigger the upload manually ONLY in non-restored case
      files
      .filter((file) => !file.isRestored)
      .forEach((file) => this.uploadFile(file.id));

      this.log(`Finished preparing files for resuming upload`);
    });

  }

  uninstall() {
    const { capabilities } = this.uppy.getState();
    this.uppy.setState({
      capabilities: {
        ...capabilities,
        resumableUploads: false,
      },
    });
    this.uppy.removePreProcessor(this.prepareUpload);
    this.uppy.removeUploader(this.handleUpload);
    this.uppy.off('resume-all', () => {
    })
  }
}

export default GDriveResumableUpload;